import { DEFAULT_ERROR_MESSAGE } from './constants';
import { FailedRequest } from './types';

export const errorToFailedRequest = async (
  response: Response
): Promise<FailedRequest> => {
  let error;

  try {
    const json = await response.json();
    if (json) {
      error = new FailedRequest(
        json.Message || DEFAULT_ERROR_MESSAGE,
        response
      );
    }
  } catch (_e) {
    error = new FailedRequest(DEFAULT_ERROR_MESSAGE, response);
  }

  return error;
};
