import { AssetManager, AssetManagerNormalized } from './types';

export const normalizeAssetManager = (
  assetManager: AssetManager
): AssetManagerNormalized => ({
  ...assetManager,
  MobilePhoneNumber:
    `${assetManager.DDDMobilePhone}${assetManager.MobilePhone}`.trim(),
  DocumentTypeId:
    assetManager.AssetManagerCpfCnpj?.trim().length === 11 ? 'CPF' : 'CNPJ',
});

export const normalizeAssetManagers = (
  assetManagers: AssetManager[]
): AssetManagerNormalized[] => assetManagers.map(normalizeAssetManager);
