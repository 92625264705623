import {
  MissingDocumentsAction,
  MissingDocument,
  RegisterClientCodeDocumentResponse,
} from './types';

import {
  MISSING_DOCUMENTS_FETCH_INIT,
  MISSING_DOCUMENTS_FETCH_SUCCESS,
  MISSING_DOCUMENTS_FETCH_ERROR,
  MISSING_DOCUMENTS_SET_INDEX,
  MISSING_DOCUMENTS_FINISH_INIT,
  MISSING_DOCUMENTS_FINISH_SUCCESS,
  MISSING_DOCUMENTS_FINISH_ERROR,
  MISSING_DOCUMENTS_FETCH_KEY_INIT,
  MISSING_DOCUMENTS_FETCH_KEY_SUCCESS,
  MISSING_DOCUMENTS_FETCH_KEY_ERROR,
} from './constants';

import Api from '../../Helpers/Api';

export const fetchMissingDocumentsInit = (
  clientId: string | number
): MissingDocumentsAction => ({
  type: MISSING_DOCUMENTS_FETCH_INIT,
  clientId,
});

export const fetchMissingDocumentsSuccess = (
  documents: MissingDocument[]
): MissingDocumentsAction => ({
  type: MISSING_DOCUMENTS_FETCH_SUCCESS,
  documents,
});

export const fetchMissingDocumentsError = (
  error: Error
): MissingDocumentsAction => ({
  type: MISSING_DOCUMENTS_FETCH_ERROR,
  error,
});

export const fetchMissingDocuments = async (
  clientId: string | number
): Promise<MissingDocument[]> => {
  return Api<MissingDocument[]>({
    service: 'terra.register',
    url: `/api/document/client/${clientId}/required`,
    authenticate: true,
    method: 'GET',
  });
};

export const postMissingDocumentsFinishInit = (
  clientId: string | number
): MissingDocumentsAction => ({
  type: MISSING_DOCUMENTS_FINISH_INIT,
  clientId,
});

export const postMissingDocumentsFinishSuccess =
  (): MissingDocumentsAction => ({
    type: MISSING_DOCUMENTS_FINISH_SUCCESS,
  });

export const postMissingDocumentsFinishError = (
  error: Error
): MissingDocumentsAction => ({
  type: MISSING_DOCUMENTS_FINISH_ERROR,
  error,
});

export const postMissingDocumentsFinish = async (
  clientId: string | number
): Promise<void> => {
  Api({
    service: 'terra.register',
    url: `/api/register/status`,
    authenticate: true,
    method: 'PATCH',
    body: {
      ClientId: clientId,
      StatusId: 2,
    },
  });
};

export const setMissingDocumentIndex = (
  index: number
): MissingDocumentsAction => ({
  type: MISSING_DOCUMENTS_SET_INDEX,
  index,
});

export const fetchMissingDocumentsKeyInit = (
  document: string
): MissingDocumentsAction => ({
  type: MISSING_DOCUMENTS_FETCH_KEY_INIT,
  document,
});

export const fetchMissingDocumentsKeySuccess = (
  key: string
): MissingDocumentsAction => ({
  type: MISSING_DOCUMENTS_FETCH_KEY_SUCCESS,
  key,
});

export const fetchMissingDocumentsKeyError = (
  error: Error
): MissingDocumentsAction => ({
  type: MISSING_DOCUMENTS_FETCH_KEY_ERROR,
  error,
});

export const fetchMissingDocumentsKey = async (
  document: string
): Promise<string> => {
  const userResponse = await Api<RegisterClientCodeDocumentResponse>({
    url: `/api/client/code/document/${document}`,
    service: 'terra.register',
    method: 'GET',
    authenticate: true,
  });

  const keyResponse = await Api<string>({
    url: `/api/customer/get-token-upload-documents/${userResponse.ClientId}`,
    service: 'terra.authentication',
    method: 'GET',
    authenticate: true,
  });

  return keyResponse;
};
