/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { RootState } from '../../Redux/store';

import Loading from '../Loading';
import allRoutes from '../../Routes/routes';

interface AuthenticatedProps {
  children: React.ReactNode;
}

const Authenticated: React.FC<AuthenticatedProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState<
    boolean | undefined
  >();
  const { auth } = useSelector((state: RootState) => state.authState);

  React.useEffect(() => {
    if (!auth) {
      setIsAuthenticated(false);
    } else {
      setIsAuthenticated(true);
    }
  }, [auth]);

  if (isAuthenticated === false) {
    return <Redirect to={allRoutes.root} />;
  }

  if (auth) {
    return <>{children}</>;
  }

  return <Loading />;
};

export default Authenticated;
