import {
  AGREEMENT_QUALIFIED_INVESTOR_FILE_INIT,
  AGREEMENT_QUALIFIED_INVESTOR_FILE_ERROR,
  AGREEMENT_QUALIFIED_INVESTOR_FILE_SUCCESS,
} from './constants';

import Api from '../../Helpers/Api';
import { AgreementAction } from './types';

const fetchFile = async (fileId) => {
  const response = await Api({
    service: 'terra.compliance.agreement',
    url: `/api/file/type/${fileId}`,
    method: 'GET',
    authenticate: true,
    returnResponse: true,
  });

  const file: Blob = new Blob([await response.blob()], {
    type: 'application/pdf',
  });
  const fileURL: string = URL.createObjectURL(file);
  return fileURL;
};

export const fetchQualifiedInvestorAgreementFileInit = (): AgreementAction => ({
  type: AGREEMENT_QUALIFIED_INVESTOR_FILE_INIT,
});

export const fetchQualifiedInvestorAgreementFileSuccess = (
  fileUrl: string
): AgreementAction => ({
  type: AGREEMENT_QUALIFIED_INVESTOR_FILE_SUCCESS,
  payload: fileUrl,
});

export const fetchQualifiedInvestorAgreementFileError = (
  error: Error
): AgreementAction => ({
  type: AGREEMENT_QUALIFIED_INVESTOR_FILE_ERROR,
  error,
});

export const fetchQualifiedInvestorAgreementFile = (): Promise<string> => {
  return fetchFile(42);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const postSignAgreementInit = async (
  fileId: number,
  electronicCheck: string
) => {
  let response;
  try {
    response = await Api({
      service: 'terra.compliance.agreement',
      url: '/api/client-agreement/type',
      method: 'POST',
      authenticate: true,
      returnResponse: true,
      body: {
        EletronicCheck: electronicCheck,
        AgreementType: fileId,
      },
    });
    return response;
  } catch (error) {
    console.log('error ', error);
  }

  return response;
};
