import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';

import './index.sass';

import { store } from './Redux/store';

import App from './App';

const isDevelopment =
  ['production', 'staging'].indexOf(process.env.NODE_ENV) === -1;

if (!isDevelopment) {
  if (process.env.SENTRY_SECRET && process.env.SENTRY_PROJECT) {
    Sentry.init({
      dsn: `https://${process.env.SENTRY_SECRET}@sentry.io/${process.env.SENTRY_PROJECT}`,
    });
  }
}

TagManager.initialize({
  gtmId: 'GTM-58SKVJK',
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept();
}
