import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Provider as ThemeProvider,
  themeGenerator,
} from '@terrainvest/react-components';
import Authenticated from '../Components/Authenticated';
import NoPartnerView from '../Components/NoPartnerView';
import SuspenseLoading from '../Components/SuspenseLoading';
import { setupIcons } from '../Helpers/Icons';
import { fetchPartnerInit, setFirstContactUrl } from '../Redux/Partner/actions';
import { RootState } from '../Redux/store';
import RoutePath, { validateCurrentRouteExistation } from './routes';

setupIcons();

const HomeScreen = React.lazy(() => import('../Screens/Home'));

const PersonTypeSelectScreen = React.lazy(
  () => import('../Screens/PersonTypeSelect')
);

const MissingDocumentsScreen = React.lazy(
  () => import('../Screens/MissingDocuments')
);
const OfferManagementScreen = React.lazy(
  () => import('../Screens/OfferManagement')
);
const Address = React.lazy(() => import('../Screens/SignUp/Address'));
const IdentityDocuments = React.lazy(
  () => import('../Screens/SignUp/IdentityDocuments')
);
const PJ = React.lazy(() => import('../Screens/SignUp/Details/TypePerson/PJ'));
const PF = React.lazy(() => import('../Screens/SignUp/Details/TypePerson/PF'));
const FinancialData = React.lazy(
  () => import('../Screens/SignUp/FinancialData')
);
const Suitability = React.lazy(
  () => import('../Screens/SignUp/Suitability/Suitability')
);
const SignatureAndTerms = React.lazy(
  () => import('../Screens/SignUp/SignatureAndTerms')
);
const UploadDocuments = React.lazy(
  () => import('../Screens/SignUp/UploadDocuments')
);
const Finished = React.lazy(() => import('../Screens/SignUp/Finished'));

const Routes: React.FC = () => {
  const { partner, fetchError } = useSelector(
    (state: RootState) => state.partnerState
  );

  const { personType } = useSelector((state: RootState) => state.customerState);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setFirstContactUrl(window.location.href));

    if (!partner) {
      dispatch(fetchPartnerInit());
    }
  }, [dispatch, partner]);

  const getLoading = () => (
    <FontAwesomeIcon
      style={{
        position: 'absolute',
        top: 'calc(50% - 1em)',
        left: 'calc(50% - 1em)',
      }}
      size="6x"
      icon={['fas', 'spinner-third']}
      spin
    />
  );

  const theme = themeGenerator();
  const getContent = () => {
    if (fetchError) {
      return (
        <ThemeProvider theme={theme}>
          <NoPartnerView />
        </ThemeProvider>
      );
    }
    if (!validateCurrentRouteExistation()) {
      return (
        <Router>
          <Redirect to={RoutePath.root} />
        </Router>
      );
    }
    if (partner) {
      return (
        <Router>
          <React.Suspense fallback={<SuspenseLoading />}>
            <Route
              path={RoutePath.root}
              exact
              strict
              component={PersonTypeSelectScreen}
            />

            <Route exact path={RoutePath.newSignup} component={HomeScreen} />
            <Route
              exact
              path={RoutePath.continueSignup}
              component={HomeScreen}
            />
            <Route path={RoutePath.signupAddress} exact>
              <Authenticated>
                <Address />
              </Authenticated>
            </Route>
            <Route path={RoutePath.signupDocuments}>
              <Authenticated>
                <IdentityDocuments />
              </Authenticated>
            </Route>
            <Route path={RoutePath.signupDetails}>
              <Authenticated>
                {personType === 'J' ? <PJ /> : <PF />}
              </Authenticated>
            </Route>
            <Route path={RoutePath.signupFinancialData}>
              <Authenticated>
                <FinancialData />
              </Authenticated>
            </Route>
            <Route path={RoutePath.signupSuitability}>
              <Authenticated>
                <Suitability />
              </Authenticated>
            </Route>
            <Route path={RoutePath.signupUploadDocuments}>
              <Authenticated>
                {personType === 'J' ? (
                  <UploadDocuments />
                ) : (
                  <Redirect to={RoutePath.signupFinancialData} />
                )}
              </Authenticated>
            </Route>
            <Route path={RoutePath.signupTerms}>
              <Authenticated>
                <SignatureAndTerms />
              </Authenticated>
            </Route>
            <Route path={RoutePath.signupFinished} exact>
              <Authenticated>
                <Finished />
              </Authenticated>
            </Route>
            <Route exact component={MissingDocumentsScreen} />
            <Route exact component={OfferManagementScreen} />
          </React.Suspense>
        </Router>
      );
    }
    return getLoading();
  };

  return <>{getContent()}</>;
};

export default Routes;
