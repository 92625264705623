import { Cmd, loop, Loop, LoopReducer } from 'redux-loop';
import {
  SIGNUP_CONTINUE_INIT,
  SIGNUP_CONTINUE_SUCCESS,
  SIGNUP_CONTINUE_ERROR,
  SIGNUP_CONTINUE_RESET,
  SIGNUP_CREATE_INIT,
  SIGNUP_CREATE_SUCCESS,
  SIGNUP_CREATE_ERROR,
} from './constants';

import {
  fetchContinueSignup,
  fetchContinueSignupSuccess,
  fetchContinueSignupError,
  postCreateCustomer,
  postCreateCustomerSuccess,
  postCreateCustomerError,
} from './actions';

import { SignupState, SignupAction } from './types';

const initialState: SignupState = {
  signupLoading: false,
  signupError: undefined,
  signupSuccess: false,
  signupContinueLoading: false,
  signupContinueError: undefined,
  signupContinueSuccess: false,
  signupExists: undefined,
  signupContinue: undefined,
  signupWaitingDocuments: undefined,
  signupCompleted: undefined,
  signupAnalyzing: undefined,
  signupDifferentPartner: undefined,
  signupDifferentPartnerUrl: undefined,
};

export const signupReducer: LoopReducer<SignupState, SignupAction> = (
  state = initialState,
  action: SignupAction
): Loop<SignupState> | SignupState => {
  switch (action.type) {
    case SIGNUP_CONTINUE_INIT:
      return loop(
        {
          ...state,
          signupContinue: undefined,
          signupContinueLoading: true,
          signupContinueError: undefined,
        },
        Cmd.run(fetchContinueSignup, {
          successActionCreator: fetchContinueSignupSuccess,
          failActionCreator: fetchContinueSignupError,
          args: [action.document, action.partnerId],
        })
      );

    case SIGNUP_CONTINUE_SUCCESS:
      return {
        ...state,
        signupContinueLoading: false,
        signupContinueSuccess: true,
        signupContinue: action.continue,
        signupAnalyzing: action.analyzing,
        signupCompleted: action.completed,
        signupExists: action.exists,
        signupWaitingDocuments: action.waitingDocuments,
        signupDifferentPartner: action.differentPartner,
        signupDifferentPartnerUrl: action.differentPartnerUrl,
      };

    case SIGNUP_CONTINUE_ERROR:
      return {
        ...state,
        signupContinueLoading: false,
        signupContinueError: action.error,
      };

    case SIGNUP_CONTINUE_RESET:
      return {
        ...state,
        signupContinueLoading: false,
        signupContinueError: undefined,
        signupContinueSuccess: false,
        signupContinue: undefined,
        signupSuccess: false,
      };

    case SIGNUP_CREATE_INIT:
      return loop(
        { ...state, signupError: undefined, signupLoading: true },
        Cmd.run(postCreateCustomer, {
          successActionCreator: postCreateCustomerSuccess,
          failActionCreator: postCreateCustomerError,
          args: [action.registerBody, action.channelId, action.personType],
        })
      );

    case SIGNUP_CREATE_SUCCESS:
      return {
        ...state,
        signupLoading: false,
        signupSuccess: action.created,
        signupError: undefined,
      };

    case SIGNUP_CREATE_ERROR:
      return {
        ...state,
        signupLoading: false,
        signupSuccess: false,
        signupError: action.error,
      };

    default:
      return state;
  }
};
