import { Procurator, ProcuratorNormalized } from './types';
import { PersonType } from '../Customer';

export const normalizeProcurator = (
  procurator: Procurator,
  personType: PersonType
): ProcuratorNormalized => ({
  ...procurator,
  MobilePhoneNumber:
    `${procurator.DDDMobilePhone}${procurator.MobilePhone}`.trim(),
  PersonType: personType,
  DocumentIssuerName: procurator.DocumentIssuerName?.trim() || '',
  DocumentNumber: procurator.DocumentNumber?.trim() || '',
});

export const normalizeProcurators = (
  procurators: Procurator[],
  personType: PersonType
): ProcuratorNormalized[] =>
  procurators.map((procurator) => normalizeProcurator(procurator, personType));
